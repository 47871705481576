import React, { useEffect, useState } from 'react';
import CardLayout from '../common/CardLayout';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowRight } from '../../icons';
import './DeliveryPage.scss';
import SearchUsers from '../common/SearchUsers';
import ThanksCard from '../common/ThanksCard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NotificationClient } from '../../services/http/apis/notificationClient';
import { UsersClient } from '../../services/http/apis/usersClient';

const validationSchema = yup.object({
  user: yup.string(),
});

const initialValues = {
  user: '',
};

const DeliveryPage = () => {
  const notificationClient = new NotificationClient();
  const { t } = useTranslation();
  const usersClient = new UsersClient();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [valueUser, setValueUser] = useState(null);
  const [transitionClass, setTransitionClass] = useState('right-to-left');

  const nextCard = (type) => {
    setTransitionClass('right-to-left');
    if (step === 0) {
      getUsers();
    } else if (step === 0 && type === 'phone') {
      setStep(2);
    } else if (step === 1 || step === 2) {
      formik.submitForm();
      setStep(3);
    } else {
      setStep(step + 1);
    }
  };

  const handelBackButton = () => {
    setTransitionClass('left-to-right');
    if (step === 0) navigate('/welcome');
    if (step === 2) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };

  const getUsers = async () => {
    try {
      await usersClient
        .getUsers()
        .then(async (res) => await usersClient.getUsers({ limit: res.pagination.totalCount }))
        .then((res) => {
          setUsers(res.users);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      await notificationClient.sendNotificationDelivery(valueUser?.email);
    },
  });

  const hasError = (field) => Boolean(formik.touched[field] && formik.errors[field]);
  const helperTextFor = (field, defaultText) => (hasError(field) ? formik.errors[field] : defaultText);

  return (
    <TransitionGroup style={{ height: '100%' }}>
      <CSSTransition key={step} classNames={transitionClass} timeout={1000}>
        <div className="form">
          {step === 0 && (
            <CardLayout title="delivery.title" onBack={handelBackButton}>
              <div className="delivery">
                <div className="delivery_text">
                  <Trans i18nKey="delivery.whom_notify" />
                </div>
                <div className="delivery_buttons">
                  <button onClick={() => nextCard('name')} className="delivery_item">
                    <Trans i18nKey="delivery.search_name" />
                    <ArrowRight />
                  </button>
                  <button onClick={() => nextCard('phone')} className="delivery_item">
                    <Trans i18nKey="delivery.search_number" />
                    <ArrowRight />
                  </button>
                </div>
              </div>
            </CardLayout>
          )}
          {step === 1 && (
            <CardLayout
              title="delivery.note_title"
              buttonText={'submit_btn'}
              onBack={handelBackButton}
              onClick={nextCard}
              disabled={formik.values.user === '' || hasError('user')}
            >
              <div className="delivery_note">
                <Trans i18nKey="delivery.note_label" />
              </div>
              <SearchUsers
                typeSearch="name"
                value={valueUser}
                helperText={helperTextFor('user')}
                onChange={(value) => formik.setFieldValue('user', value)}
                onBlur={formik.handleBlur}
                error={hasError('user')}
                users={users}
                setValueUser={setValueUser}
                placeholder={t('delivery.note_placeholder')}
              />
            </CardLayout>
          )}
          {step === 2 && (
            <CardLayout
              title="delivery.note_title"
              buttonText={'submit_btn'}
              onBack={handelBackButton}
              onClick={nextCard}
              disabled={formik.values.user === '' || hasError('user')}
            >
              <div className="delivery_note">
                <Trans i18nKey="delivery.note_phone_label" />
              </div>
              <SearchUsers
                typeSearch="phone"
                value={valueUser}
                helperText={helperTextFor('user')}
                onChange={(value) => formik.setFieldValue('user', value)}
                onBlur={formik.handleBlur}
                error={hasError('user')}
                users={users}
                setValueUser={setValueUser}
                placeholder={t('delivery.note_phone_placeholder')}
              />
            </CardLayout>
          )}
          {step === 3 && (
            <ThanksCard user={valueUser} text="delivery.thanks_text" onClick={() => navigate('/welcome')} />
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DeliveryPage;
